import { Link } from 'gatsby';
import * as React from "react";
import { Layout, LayoutProps } from './Layout';
import { container, content, impressumlink } from "./LandingPageLayout.module.css";

export type LandingPageLayoutProps = LayoutProps;

export const LandingPageLayout: React.FC<React.PropsWithChildren<LandingPageLayoutProps>> = ({ pageTitle, children }) => {
  return (
    <Layout pageTitle={pageTitle}>
      <div className={impressumlink}>
        <Link to="/impressum">Impressum</Link>
      </div>
      <div className={container}>
        <div className={content}>
          {children}
        </div>
      </div>
    </Layout>
  )
}
