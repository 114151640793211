import { Link } from 'gatsby';
import * as React from "react";
import { Helmet } from "react-helmet";
import { main } from "./Layout.module.css";

export interface LayoutProps {
  pageTitle: string;
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ pageTitle, children }) => {
  return (
    <main className={main}>
      <title>{pageTitle}</title>
      <Helmet>
        <title>MINIHAUS</title>
        <script data-host="https://microanalytics.io" data-dnt="false" src="https://microanalytics.io/js/script.js" id="ZwSg9rf6GA" async defer></script>
        <script type="text/javascript">{`
          var _iub = _iub || [];
          _iub.csConfiguration = {
            "invalidateConsentWithoutLog": true,
            "consentOnContinuedBrowsing": false,
            "whitelabel": false,
            "lang": "de",
            "floatingPreferencesButtonDisplay": "bottom-right",
            "siteId": 2522741,
            "countryDetection": true,
            "perPurposeConsent": true,
            "googleAdditionalConsentMode": true,
            "enableTcf": true,
            "cookiePolicyId": 43210087, 
            "banner": { 
              "closeButtonRejects": true,
              "acceptButtonDisplay": true,
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "position": "float-top-center",
              "acceptButtonColor": "#ffffff",
              "acceptButtonCaptionColor": "#010101",
              "customizeButtonColor": "#38495a",
              "customizeButtonCaptionColor": "white",
              "textColor": "white",
              "backgroundColor": "#2f7575",
              "rejectButtonDisplay": true,
              "rejectButtonColor": "#e54141",
              "rejectButtonCaptionColor": "white",
              "listPurposes": true 
            }};
        `}</script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async></script>
      </Helmet>
      {children}
    </main>
  )
}
